body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #20242A !important;
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*mainBackground: '#131518',*/
/*secondaryBackground: '#20242A',*/
/*unchecked: '#2B3038',*/
/*primary: '#F7B500',*/
/*mainColor: '#ffffff',*/
/*secondaryColor: '#8E8E93',*/
/*darkColor: '#000000',*/
/*inputBorder: '#444A52',*/
/*iconColor: '#8E8E93',*/
/*errorColor: '#E02020',*/
/*separatorGradientStart: 'rgba(255,255,255,0)',*/
/*separatorGradientEnd: '#ffffff',*/
/*green: '#6DD400',*/
/*greenDark: '#5BA800',*/
