div#root {
  /*display: flex;*/
  /*justify-content: center;*/
}

/*.row {*/
/*  margin: 0 !important;*/
/*}*/
/*.row > * {*/
/*  padding-right: 4px !important;*/
/*  padding-left: 4px !important;*/
/*}*/

.MapsContainer {
  width: 100vw;
  height: 100vh;
}

.RequestForm {
  text-align: left;
  width: 100%;
  padding: 16px;
  margin-top: 35px;
  margin-left: 10px;
  max-width: 730px;
  background: rgb(32 36 42 / 95%) !important;
  position: relative;
  border-radius: 16px;
  box-shadow: rgb(0 0 0 / 20%) 0px 20px 30px;
  flex: 1;
  display: flex;
}

.UserInfo {
  font-size: calc(12px);
}

.works {
  font-size: calc(12px);
  padding: 10px 5px;
}

.card {
  background: #131518 !important;
  border-radius: 16px !important;
  width: 215px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 2px;
}

.card-body {
  padding-top: 0 !important;
}

.title {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.264px;
  color: #FFFFFF;
  align-items: center;
  display: flex;
}

.sub-title {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.264px;
  color: #FFFFFF;
  padding-top: 10px;
  margin-bottom: 0px;
}

.field-data {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.36px;
  color: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding: 4px 0;
}

.field-data label {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.24px;
  color: #8E8E93;
  padding-bottom: 4px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.0001) 0%, #FFFFFF 49.57%, rgba(255, 255, 255, 0.0001) 100%);
}

.icon-assistance {
  padding-right: 10px;
  width: 26px;
  height: 26px;
}

.icon-status {
  padding-left: 12px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus {
  -webkit-box-shadow: inset 0 0 0 50px #131518 !important;
  -webkit-text-fill-color: #fff !important;
  border: 1px solid #131518;
  background-color: #131518 !important;
}
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
.form-select,
.form-control:focus,
.form-control:disabled,
.form-control[readonly],
.form-control {
  background-color: #131518 !important;
  height: 36px !important;
  border-radius: 10px !important;
  border: none !important;
  color: #fff !important;
  box-shadow: none !important;
  padding: 5px 10px !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 14px !important;
}
.form-control:disabled,
.form-control[readonly] {
  color: #8E8E93 !important;
}

.form-text,
.form-label {
  margin-bottom: 0.5rem;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: normal;
  font-size: 12px !important;
  line-height: 14px !important;
  color: #8E8E93 !important;
}

.form-label {
  margin-bottom: 5px;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

button.btn {
  height: 32px;
  /*background: #F7B500;*/
  border-radius: 8px;
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  letter-spacing: -0.385882px;
  color: #fff;
}

button.btn.btn-warning {
  color: #000;
}

.scroll-content {
  overflow-y: scroll;
}

.content {
  padding-left: 16px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
}

.btn-del {
  padding: 0px 10px;
}
.form-text {
  margin-top: 0;
  font-size: 12px;
  color: #6c757d;
}
.jobs .field-data {
  padding: 2px 10px;
}
.jobs-header .field-data{
  padding: 2px 10px;
}
.jobs-header .field-data label{
  padding: 0;
}

.to-pay {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 18px;
  letter-spacing: 0.0145455em;
  color: #FFFFFF;
}

.to-pay .amount {
  font-family: SF Pro Display;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  letter-spacing: 0.0128571em;
  color: #FFFFFF;
}

.to-pay .currency {
  font-family: SF Pro Text;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.385882px;
  color: #FFFFFF;
}
.modal-content {
  background-color: #20242A !important;
  border: 1px solid #20242A !important;
  border-radius: 16px !important;
}
.modal-header {
  border-bottom: none !important;
}
.modal-footer {
  border-top: none !important;
}

.modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.job-wrap {
  overflow-y: scroll;
  max-height: 200px;
}
.row{
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.job-wrap .row{
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.job-wrap .col-11 {
  padding: 0;
}
