.main-container {
    background-color: #20242A;
    margin-bottom: 32px;
}

.title-mobile {
    color: #FFFFFF;
    font-size: 28px;
    font-family: SF Pro Display;
    font-style: normal;
    font-weight: bold;
    line-height: 34px;
    letter-spacing: 0.012em;
}

.service-icon {
    width: 24px;
    height: 24px;
    margin-right: 15px;
}

.button {
    height: 48px !important;
    width: 100%;
    margin-top: 16px;
    font-size: 16px !important;
}

.button-wrapper {
    margin: 8px 16px 0;
}

.label {
    font-family: SF Pro;
    font-size: 15px;
    line-height: 20px;
    color: #8E8E93;
    font-weight: 500;
    letter-spacing: -0.24px;
    margin-top: 8px;
}

.form-label {
    margin-top: 24px;
}

.text {
    font-family: SF Pro;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    font-weight: 500;
    letter-spacing: -0.36px;
    margin-top: 8px;
    text-transform: capitalize;
}

.info-title {
    font-family: SF Pro;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
    font-weight: 600;
    letter-spacing: -0.024em;
    text-transform: uppercase;
}

.google-link {
    color: #2C6FF9;
    text-decoration: underline;
}

.google-link:hover {
    color: #2C6FF9;
    text-decoration: underline;
}

.link:hover {
    color: #FFFFFF;
}

.link:focus-visible {
    border: none;
}

.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 21px;
}

.header-container {
    background-color: #131518;
    padding: 27px 16px 16px;
}

.yellow-text {
    color: #F7B500
}

.input {
    width: 100%;
    background-color: #131518;
    border: none;
    border-radius: 8px;
    padding: 12px;
    color: #FFFFFF;
    margin-top: 8px;
}

.input-gray {
    color: #8E8E93;
}

.input :focus-visible {
    outline: none;
}

.delete-icon {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: #C52C44;
    display: flex;
    align-items: center;
    justify-content: center;
}

.status-text {
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.016em;
    color: #FFFFFF;
    font-family: 'SF Pro Text';
}

.flex-row {
    display: flex;
    align-items: center;
}

.justify-space-btw {
    justify-content: space-between;
}

.align-end {
    align-self: end;
}

.price {
    font-family: "SF Pro Display";
    font-size: 15px;
    line-height: 18px;
    font-weight: 700;
    letter-spacing: -0.38px;
    color: #F7B500;
}

.icon-container {
    width: 36px;
    height: 36px;
    border-radius: 8px;
    background-color: #131518;
    display: flex;
    align-items: center;
    justify-content: center;
}

.details {
    font-family: "SF Pro Text";
    font-size: 13px;
    line-height: 18px;
    font-weight: 200;
    letter-spacing: -0.006em;
    color: #8E8E93;
}

.job-container {
    border-bottom: 1px solid #8E8E93;
    padding-bottom: 16px;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #131518CC;
    z-index: 99;
}

.modal-container {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: #20242A;
    padding: 16px 16px 30px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    z-index: 999;
    transition: all 300ms ease-in;
}

.body-modal {

}

.header-modal {
    font-family: "SF Pro Display";
    font-size: 24px;
    line-height: 29px;
    font-weight: 700;
    letter-spacing: 0.264px;
    color: #FFFFFF;
    align-self: center;
}

.map-container {
    background-color: #131518;
    padding: 8px 12px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    font-family: "SF Pro Display";
    font-size: 17px;
    line-height: 20px;
    letter-spacing: -0.006em;
    color: #FFFFFF;
    position: absolute;
    top: 60px;
    left: 10px;
}

.icon-mr {
    margin-right: 16px;
}


